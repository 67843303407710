// Constantes
import { COMPANY_TYPES, DEFAULT_LANGUAGE, MENUS_TYPES } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Services
import {
  createCategory,
  updateCategoryById,
  getCategoryById,
  deleteCategoryById,
  deleteEveryCategoriesAndDishesByCategoryId
} from '@/services/category'
import { deleteEveryDishesByCategoryId, setEveryPricesToNullByCategoryId } from '@/services/dish'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'CategoryForm',
  components: { FormButtons, VuetifyContentLoading },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    // Idiomas adicionales
    additionalLanguages: {
      type: Array,
      default() {
        return []
      }
    },
    // Mostramos las opciones de la marca
    brandOptions: {
      required: true,
      default: true,
      type: Boolean
    },
    // Datos de moneda
    currency: {
      type: Object,
      default() {
        return {}
      }
    },
    // Idioma por defecto
    defaultLanguage: {
      type: String,
      default: DEFAULT_LANGUAGE
    },
    // Id de la categoría (edición)
    id: {
      type: String,
      default: null
    },
    // Modelo relacionado a la carta/menú ('places' o 'companies')
    model: {
      required: true,
      default: 'places',
      type: String
    },
    // UID del modelo en BD
    modelId: {
      required: true,
      type: String
    },
    // Id de la categoría padre, si existiera
    parentId: {
      type: String,
      default: null
    },
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form
      formFields: {
        description: null,
        disabled: false,
        name: null,
        price: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        price: {
          required: 'Debes indicar un precio'
        }
      },
      // Others
      categoryData: null,
      currentId: this.id,
      isMenu: false,
      isTAD: false,
      parentCategoryData: null,
      processingRequest: false
    }
  },
  computed: {
    /**
     * Currency Symbol
     *
     * @return {String}
     */
    currencySymbol() {
      return get(this.currency, 'symbol', null)
    },
    /**
     * Opciones de la columna del campo del
     * formulario "disabled"
     *
     * @return {object}
     */
    disabledColumnOptions() {
      return this.brandOptions ? { md: '5', 'offset-md': '1' } : { md: '6', 'offset-md': '3' }
    },
    /**
     * Get the labels to show in the formulary
     *
     * @return {Object} - diffent labels
     */
    formLabels() {
      const labels = {
        labelButtonSave: 'Guardar',
        labelButtonCancel: 'Cerrar',
        labelButtonDelete: !this.parentId
          ? '¿Estas seguro que quieres eliminar la carta/menú?'
          : '¿Estas seguro que quieres eliminar la categoría?',
        labelNotAvailable: !this.parentId ? 'Carta/Menú no disponible' : 'Categoría no disponible',
        labelNotEditable: !this.parentId ? 'Carta/Menú editable' : 'Categoría editable'
      }

      if (this.parentId) {
        labels.labelNotAvailable = 'Categoría no disponible'
        labels.labelNotEditable = 'Categoría editable'
      } else if (!this.parentId && !this.isMenu) {
        labels.labelNotAvailable = 'Carta no disponible'
        labels.labelNotEditable = 'Carta editable'
      } else if (!this.parentId && this.isMenu) {
        labels.labelNotAvailable = 'Menú no disponible'
        labels.labelNotEditable = 'Menú editable'
      }

      return labels
    }
  },
  watch: {
    isMenu(newValue) {
      if (newValue === false) {
        this.formFields.price = null
      }
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Añadimos campos adicionales de la marca (brand) para
     * cuando este componente se use para la creación de una
     * carta o categoría que depende
     */
    addBrandFormFiels() {
      this.formFields = {
        ...this.formFields,
        allowCreateCategories: true,
        allowCreateProducts: true,
        editabled: true
      }
    },
    /**
     * When the user must click on delete button
     */
    async handleDeleteButton() {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          // Removing item
          await this.deleteCategory()
          // Hide dialog
          this.hideDialog()
          // Emitimos evento general para que cualquier lista
          // a la escucha, recargue su listado
          this.$root.$emit('reload-list')
        },
        actionButtonText: 'Borrar',
        text: this.formLabels.labelButtonDelete,
        type: 'warning',
        visible: true
      })
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Modificamos el atributo "itemsData" del componente padre
     *
     * @param {Object} data - datos a emitir
     */
    changeItemsData(data) {
      this.$parent.$parent.$parent.$emit('onChangeItemsData', this.index, data)
    },
    /**
     * Esta función es llamada desde el padre (VuetifyTabs)
     * cuando el usuario pulsa sobre alguno de las pestañas
     *
     * @param {Number} tab - pestaña donde deseamos movernos
     */
    async fnToChangeTab(tab) {
      let result = false

      try {
        if (isNil(this.currentId)) {
          // Create categorie
          result = await this.onSubmit()
        } else {
          // Update categorie
          result = this.actionSubmit()
        }

        if (result) {
          this.$parent.$parent.$parent.$emit('onChangeTab', tab)
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * Inicializamos/obtenemos todos los datos necesarios
     * del componente
     */
    async getEveryNeededData() {
      try {
        // Añadimos campos de edición del brand
        if (this.brandOptions) {
          this.addBrandFormFiels()
        }

        // Edición
        if (this.currentId) {
          // Loading
          this.processingRequest = true
          // Get data
          this.categoryData = await this.getCategoryData(this.currentId)
          // Para que los datos estén disponibles para el resto de pestañas (VuetifyTabs)
          this.changeItemsData({
            data: this.categoryData
          })
          // Set form variables
          this.setFormFieldsValues(this.categoryData)
          // Es una categoría padre?
          if (isNil(this.categoryData.parentId)) {
            this.isMenu = Boolean(this.categoryData.price) && !this.parentId
            this.isTAD = this.categoryData.type === MENUS_TYPES.takeAway.value
          }
        }

        // ¿Tenemos una categoría padre (menú)?
        if (!isNil(this.parentId)) {
          this.parentCategoryData = await this.getCategoryData(this.parentId)
          this.isMenu = Boolean(this.parentCategoryData.price) && !this.parentId
          this.isTAD = this.parentCategoryData.type === MENUS_TYPES.takeAway.value
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
        // hide dialog
        this.hideDialog()
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Get the data of current category
     *
     * @param {String} id - UID category
     * @return {Object}
     */
    async getCategoryData(id) {
      const categoryData = await getCategoryById(id)
      return categoryData
    },
    /**
     * Establecemos los valores iniciales de los
     * campos del formulario
     *
     * @param {object} data - objeto con los datos a establecer
     */
    setFormFieldsValues(data = {}) {
      // Datos generales
      this.formFields.name = get(data, 'name', this.formFields.name)
      this.formFields.description = get(data, 'description', this.formFields.description)
      this.formFields.disabled = get(data, 'disabled', this.formFields.disabled)
      this.formFields.price = get(data, 'price', this.formFields.price)

      // Datos de marca
      if (this.brandOptions) {
        this.formFields.allowCreateCategories = get(
          data,
          'allowCreateCategories',
          this.formFields.allowCreateCategories
        )
        this.formFields.allowCreateProducts = get(
          data,
          'allowCreateProducts',
          this.formFields.allowCreateProducts
        )
        this.formFields.editabled = get(data, 'editabled', this.formFields.editabled)
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Update categorie in DB
      await this.saveCategory()
      // Emitimos evento general para que cualquier lista
      // a la escucha, recargue su listado
      this.$root.$emit('reload-list')
    },
    /**
     * Differents actions to update the category in
     * the database
     */
    async saveCategory() {
      if (this.currentId) {
        // Datos a salvar (Actualización)
        const dataToSave = {
          id: this.currentId,
          ...this.formFields
        }

        await updateCategoryById(dataToSave, {
          additionalLanguages: this.additionalLanguages,
          defaultLanguage: this.defaultLanguage
        })

        // Carta -> Menú, establecemos todos los precios a NULL
        if (isNil(this.parentId) && !isNil(this.formFields.price)) {
          const { ok } = await setEveryPricesToNullByCategoryId(this.currentId)
          if (!ok) {
            throw new Error('No se pudieron establecer los precios de los platos.')
          }
        }
      } else {
        // Creación
        const { category } = await createCategory(
          {
            ...this.formFields,
            // Solo categorías "padres"
            ...(isNil(this.parentId)
              ? {
                  [this.model]: [this.modelId],
                  type: this.isTAD ? MENUS_TYPES.takeAway.value : MENUS_TYPES.place.value
                }
              : {}),
            parentId: this.parentId,
            // propietario de la categoría
            ...(this.brandOptions ? { owner: COMPANY_TYPES.brand } : {})
          },
          null,
          {
            additionalLanguages: this.additionalLanguages,
            defaultLanguage: this.defaultLanguage
          }
        )

        // Establecemos el nuevo ID de la categoría
        this.currentId = category.id
        // Establecemos los nuevos datos de la categoría
        this.categoryData = category
      }

      // Para que los datos estén disponibles para el resto de pestañas (VuetifyTabs)
      this.changeItemsData({
        data: this.categoryData
      })

      // No tenemos pestañas de edición
      if (this.isTAD) {
        this.hideDialog()
      }

      // Información resultados acción de salvado
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    },
    /**
     * Different actions to delete the categories
     * and every associated to it
     */
    async deleteCategory() {
      try {
        // Loading
        this.formProcessing = true

        // Categoría padre (es un menú o carta)
        if (!this.categoryData.parentId) {
          // Eliminamos categorías hijas y platos
          await deleteEveryCategoriesAndDishesByCategoryId(this.categoryData.id)
          // Eliminamos categoría
          await deleteCategoryById(this.categoryData.id)
        } else {
          // Eliminamos platos asociados
          const { ok } = await deleteEveryDishesByCategoryId(this.categoryData.id)

          if (!ok) {
            throw new Error('No se pudieron eliminar los platos asociados a la categoría.')
          }

          // Eliminamos categoría
          await deleteCategoryById(this.categoryData.id)
        }
      } catch (error) {
        // show error
        this.handleError(error.message)
      } finally {
        // Loading
        this.formProcessing = true
      }
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  },
  // Validations with Vuelidate
  validations() {
    const rules = {
      formFields: {
        description: {},
        disabled: {},
        name: {
          required
        },
        price: {}
      }
    }

    // Validación de opciones de marca
    if (this.brandOptions) {
      rules.formFields.allowCreateCategories = {}
      rules.formFields.allowCreateProducts = {}
      rules.formFields.editabled = {}
    }

    // validación de menú
    if (this.isMenu) {
      rules.formFields.price = {
        required
      }
    }

    return rules
  }
}
