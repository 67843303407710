// Components
import CategoryForm from '../CategoryForm'
import CategoryScheduleForm from '../CategoryScheduleForm'
import TranslateForm from '@/components/elements/translate/TranslateForm'
import VuetifyTabs from '@/components/ui/VuetifyTabs'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'CategoryFormByTabs',
  components: {
    VuetifyTabs
  },
  mixins: [addonsMixin],
  props: {
    // Id de la categoría (edición)
    id: {
      type: String,
      default: null
    },
    // Modelo relacionado a la carta/menú ('places' o 'companies')
    model: {
      required: true,
      default: 'places',
      type: String
    },
    // UID del modelo en BD
    modelId: {
      required: true,
      type: String
    },
    // Id de la categoría padre, si existiera
    parentId: {
      type: String,
      default: null
    },
    // Pestañas "adicionales" de configuración
    tabs: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Establecemos/obtenemos todos los datos
     * necesarios para inicializar el componente
     */
    getEveryNeededData() {
      // Pestañas disponibles
      const basicTab = this.tabs.find((tab) => tab.id === 'basic')
      const translationTab = this.tabs.find((tab) => tab.id === 'translation')
      const scheduleTab = this.tabs.find((tab) => tab.id === 'schedule')

      // Pestaña datos básicos
      if (!isNil(basicTab)) {
        this.items.push({
          label: 'Básico',
          component: CategoryForm,
          componentProps: {
            id: this.id,
            model: this.model,
            modelId: this.modelId,
            parentId: this.parentId,
            ...get(basicTab, 'options', {})
          }
        })
      }

      // Pestaña multi-idiomas
      if (!isNil(translationTab)) {
        this.items.push({
          label: 'Traducciones',
          component: TranslateForm,
          componentProps: { id: this.id, model: 'category', ...get(translationTab, 'options', {}) }
        })
      }

      // Pestaña horarios
      if (!isNil(scheduleTab)) {
        this.items.push({
          label: 'Horarios',
          component: CategoryScheduleForm,
          componentProps: { id: this.id, ...get(scheduleTab, 'options', {}) }
        })
      }
    }
  }
}
