// Request
import { doRequest } from '@/utils/requestApi'
// Utils
import { isNil } from 'lodash'

/**
 * Get categories by parentId
 *
 * @param {string} parentId - UID category in database
 */
export async function getCategoriesByParentId(parentId) {
  const result = await doRequest({
    method: 'get',
    url: `v1/category/childs/${parentId}`
  })

  return result.data
}
/**
 * Get all categories by parentIds
 *
 * @param {Array} parentIds - UID category in database
 */
export async function getEveryCategoriesByParentIds(parentIds) {
  if (Array.isArray(parentIds)) {
    const allCategories = await Promise.all(
      parentIds.map(async (id) => {
        return await getCategoriesByParentId(id)
      })
    )

    // "aplanamos" los arrays
    return allCategories.reduce((sumCategories, category) => {
      sumCategories.push(...category)
      return sumCategories
    }, [])
  }
  return []
}
/**
 * Obtenemos el padre (carta) menú de la categoría indicada
 *
 * @param {string} id - UID category in database
 * @return {object | null}
 */
export async function getParentCategoryByChildId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/category/parent/${id}`
  })

  return result.data
}
/**
 * Create new category
 *
 * @param {Object} data - category data
 */
export async function createCategory(data, id = null) {
  const result = await doRequest({
    method: 'post',
    url: isNil(id) ? 'v1/category' : `v1/category/${id}`,
    data: {
      data
    }
  })

  return result.data
}

/**
 * Update category in database
 *
 * @param {Object} data - category data
 */
export async function updateCategoryById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/category/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}

/**
 * Get category by UID
 *
 * @param {string} id - UID category in database
 */
export async function getCategoryById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/category/${id}`
  })

  return result.data
}

/**
 * Obtenemos todos los menus/cartas dado el id de la compañía
 *
 * @param {String} id - UID company in database
 */
export async function getEveryMenusByCompanyId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/category/menus/company/${id}`
  })

  return result.data
}

/**
 * Obtenemos todos los menus/cartas dado el id del establecimiento
 *
 * @param {String} id - UID place in database
 */
export async function getEveryMenusByPlaceId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/category/menus/place/${id}`
  })

  return result.data
}

/**
 * Obtiene todas las categorías y platos asociados a un menú o carta
 *
 * @param {string} id - UID category in database
 */
export async function getAvailableCategoriesAndProductsByCategoryId(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/category/every/${id}`
  })

  return result.data
}

/**
 * Remove the category by id
 *
 * @param {string} id - UID category in database
 */
export async function deleteCategoryById(id) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/category/${id}`
  })

  return result.data
}

/**
 * Remove all child items by parentId and dishes associated
 *
 * @param {string} parentId - UID category in database
 */
export async function deleteEveryCategoriesAndDishesByCategoryId(parentId) {
  const result = await doRequest({
    method: 'delete',
    url: `v1/category/every/${parentId}`
  })

  return result.data
}
