// Components
import FormButtons from '@/components/ui/FormButtons'
import VuetifySchedules from '@/components/ui/VuetifySchedules'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Services
import { updateCategoryById, getCategoryById } from '@/services/category'
// Utils
import { get } from 'lodash'

export default {
  name: 'CategoryScheduleForm',
  components: {
    FormButtons,
    VuetifySchedules,
    VuetifyContentLoading
  },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    id: {
      type: String,
      default: null
    },
    // Variables del componente padre "VuetifyTabs"
    index: {
      // Indice que ocupo dentro del componente
      type: Number,
      default: 0
    },
    itemsData: {
      // Datos que se comparten entre pestañas del componente
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      // Form
      formFields: {
        schedule: [],
        enabledSchedule: false
      },
      formFieldsValidations: {},
      // Other
      modelId: this.id,
      processingRequest: true
    }
  },
  watch: {
    itemsData: {
      async handler(value) {
        // Los datos que deseamos tomar "normalmente"
        // vienen de la primera pestaña
        const prevData = get(value, '0', false)

        if (prevData) {
          const { id } = prevData
          await this.setFormFieldsValues(id)
        }
      },
      immediate: true
    },
    '$v.formFields.enabledSchedule.$model'(value) {
      if (value === false) {
        this.formFields.schedule = []
      }
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Evento lanzado tras la modificación del horario
     *
     * @param {Array} value - horarios
     */
    handleSchedule(value) {
      this.formFields.schedule = value
    },
    /**
     * Obtiene todos los datos y elementos necesarios
     * para inicializar la vista
     */
    async getEveryNeededData() {
      await this.setFormFieldsValues(this.id)
    },
    /**
     * Establecemos los datos del menú que estamos tratando
     * y los valores iniciales del formulario
     *
     * @param {string} id - id del menú (categoría)
     */
    async setFormFieldsValues(id) {
      try {
        if (id) {
          const menuData = await getCategoryById(id)

          if (menuData) {
            // Establecemos los valores
            this.modelId = menuData.id
            this.formFields.schedule =
              typeof menuData.schedule !== 'undefined' ? menuData.schedule : []
            this.formFields.enabledSchedule =
              typeof menuData.enabledSchedule !== 'undefined' ? menuData.enabledSchedule : false
          }
        }
      } catch (error) {
        // show error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Actualización
      await updateCategoryById({
        id: this.modelId,
        ...this.formFields
      })
      // show info
      this.modifyAppAlert({
        text: 'Los cambios se guardaron correctamente',
        visible: true
      })
    }
  },
  validations: {
    formFields: {
      enabledSchedule: {},
      schedule: {}
    }
  }
}
